import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Icon from '../components/Icon';

const SuccessPage = () => (
  <Layout>
    <SEO title="Success: message sent" />
    <div className="flex items-center justify-center flex-1 w-full mx-auto">
      <div className="max-w-sm p-4">
        <h2 className="w-full mb-4 text-4xl font-bold leading-none text-green-500 uppercase">
          Form submitted successfully!
        </h2>
        <p className="mb-4">
          Thank you for your message. We will get back to you as soon as we can.
        </p>
        <p>
          <Link
            className="inline-flex items-center px-4 py-2 text-xs font-bold leading-none tracking-wide text-white uppercase bg-green-500 cursor-pointer hover:bg-green-400"
            to="/"
          >
            <Icon />
            Take Me Home
          </Link>
        </p>
      </div>
    </div>
  </Layout>
);

export default SuccessPage;
